import { IResearchFilterState, IResearchFilterTimeModes, IResearchFilterDimension, IResearchFilterDimensionTransaction, IResearchExtractTypes, IResearchShowCategoryTypes, IResearchValueTypes, IResearchTimeChartAggregations, IResearchTimeChartAggregationsSavings, IResearchValueTypesSavings } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { IResearchFilterDimensionCategory, ITransactionHeader  } from '@/interfaces/research';


export const mutations = {
  setResearchFilterTimePeriod(state: IResearchFilterState, payload: any) {
    state.time = payload;
  },
  setResearchFilterMinTime(state: IResearchFilterState, payload: string) {
    state.time.minTime = payload;
  },
  setResearchFilterMaxTime(state: IResearchFilterState, payload: string) {
    state.time.maxTime = payload;
  },
  setResearchFilterTimeCurrentPeriod(state: IResearchFilterState, payload: IResearchFilterTimeModes) {
    state.lastFilterInteractionSavedView = false;
    state.time.currentPeriod = payload;
  },
  setResearchFilterTimeCurrentPeriodValues(state: IResearchFilterState, payload: string[]) {
    state.lastFilterInteractionSavedView = false;
    state.time.currentPeriodValues = payload;
  },
  setResearchFilterTimeComparePeriodSingle(state: IResearchFilterState, payload: {periodKey: string, payload: string}) {
    state.lastFilterInteractionSavedView = false;
    state.time.comparePeriod[payload.periodKey] = payload.payload;
  },
  setResearchFilterTimeComparePeriodValues(state: IResearchFilterState, payload: string[]) {
    state.lastFilterInteractionSavedView = false;
    state.time.comparePeriodValues = payload;
  },
  setResearchFilterDimensions(state: IResearchFilterState, payload: IResearchFilterDimension[]) {
    state.dimensions = payload;
  },
  setResearchFilterDimensionsTransactions(state: IResearchFilterState, payload: IResearchFilterDimensionTransaction[]) {
    state.dimensionsTransactions = payload;
  },
  setResearchFilterDimensionCompleteSingle(state: IResearchFilterState, payload: {index: number, payload: IResearchFilterDimension}) {
    state.lastFilterInteractionSavedView = false;
    state.dimensions[payload.index] = payload.payload;
  },
  setResearchFilterDimensionSingle(state: IResearchFilterState, payload: {index: number, payload: object[]}) {
    state.lastFilterInteractionSavedView = false;
    const payloadMod = JSON.parse(JSON.stringify(payload));
    state.dimensions[payloadMod.index].filteredValues =  JSON.parse(JSON.stringify(payloadMod.payload));
  },
  setResearchFilterDimensionLoaded(state: IResearchFilterState, payload: boolean) {
    state.dimensionsLoaded = payload;
  },
  setResearchFilterCategoryDrillPath(state: IResearchFilterState, payload: IResearchFilterDimensionCategory[]) {
    state.lastFilterInteractionSavedView = false;
    state.categoryDrillPath = payload;
  },
  setResearchFilterShowCategoryType(state: IResearchFilterState, payload: IResearchShowCategoryTypes) {
    state.lastFilterInteractionSavedView = false;
    state.showCategoryTypes = payload;
  },
  setResearchExtractTypeSwitchInitialized(state: IResearchFilterState, payload: boolean) {
    state.extractTypeSwitchInitialized = payload;
  },
  setResearchExtractTypeSwitchEnabled(state: IResearchFilterState, payload: boolean) {
    state.extractTypeSwitchEnabled = payload;
  },
  setResearchExtractTypeSwitchValue(state: IResearchFilterState, payload: IResearchExtractTypes | undefined) {
    state.lastFilterInteractionSavedView = false;
    state.extractTypeSwitchValue = payload;
  },
  setResearchQuantitiesEnabled(state: IResearchFilterState, payload: boolean) {
    state.quantitiesEnabled = payload;
  },
  setResearchHarmonized(state: IResearchFilterState, payload: boolean) {
    state.lastFilterInteractionSavedView = false;
    state.harmonized = payload;
  },
  setResearchValueType(state: IResearchFilterState, payload: IResearchValueTypes) {
    state.lastFilterInteractionSavedView = false;
    state.valueType = payload;
  },
  setResearchValueTypeSavings(state: IResearchFilterState, payload: IResearchValueTypesSavings) {
    state.lastFilterInteractionSavedView = false;
    state.valueTypeSavings = payload;
  },
  setResearchTimeChartAggregation(state: IResearchFilterState, payload: IResearchTimeChartAggregations) {
    state.lastFilterInteractionSavedView = false;
    state.timeChartAggregation = payload;
  },
  commitSetResearchTimeChartAggregationSavings(state: IResearchFilterState, payload: IResearchTimeChartAggregationsSavings) {
    state.lastFilterInteractionSavedView = false;
    state.timeChartAggregationSavings = payload;
  },
  toggleFilterReloadTrigger(state: IResearchFilterState) {
    state.reloadTrigger = !state.reloadTrigger;
  },
  toggleFilterReloadTriggerTime(state: IResearchFilterState) {
    state.reloadTriggerTime = !state.reloadTriggerTime;
  },
  setDrillChangeElement(state: IResearchFilterState, payload: {drillChangeElement: IResearchFilterDimensionCategory[], setFilter: boolean}) {
    state.categoryDrillChangeElement = payload;
  },
  setLastInteractionSavedView(state: IResearchFilterState, payload: boolean) {
    state.lastFilterInteractionSavedView = payload;
  },
  setSavedViewValue(state: IResearchFilterState, payload: object) {
    state.savedViewValue = payload;
  },
  setSavingsPeriodAccrual(state: IResearchFilterState, payload: boolean) {
    state.savingsPeriodAccrual = payload;
  },
  setSavingsPeriodShowAll(state: IResearchFilterState, payload: boolean) {
    state.savingsShowAll = payload;
  },
  setModuleSpecificDimensions(state: IResearchFilterState, payload: {key: string, dimensions: string[]}) {
    state.moduleSpecificDimensions[payload.key] = payload.dimensions;
  },
  setTransactionSelectedHeaders(state: IResearchFilterState, payload: ITransactionHeader[]) {
    state.transactionSelectedHeaders = payload;
  }
};

const {commit} = getStoreAccessors<IResearchFilterState | any, State>('');

export const commitSetResearchFilterTimePeriod = commit(mutations.setResearchFilterTimePeriod);
export const commitSetResearchFilterMinTime = commit(mutations.setResearchFilterMinTime);
export const commitSetResearchFilterMaxTime = commit(mutations.setResearchFilterMaxTime);
export const commitSetResearchFilterTimeCurrentPeriod = commit(mutations.setResearchFilterTimeCurrentPeriod);
export const commitSetResearchFilterTimeCurrentPeriodValues = commit(mutations.setResearchFilterTimeCurrentPeriodValues);
export const commitSetResearchFilterTimeComparePeriodSingle = commit(mutations.setResearchFilterTimeComparePeriodSingle);
export const commitSetResearchFilterTimeComparePeriodValues = commit(mutations.setResearchFilterTimeComparePeriodValues);
export const commitSetResearchFilterDimensions = commit(mutations.setResearchFilterDimensions);
export const commitSetResearchFilterDimensionSingle = commit(mutations.setResearchFilterDimensionSingle);
export const commitSetResearchFilterDimensionCompleteSingle = commit(mutations.setResearchFilterDimensionCompleteSingle);
export const commitSetResearchFilterDimensionLoaded = commit(mutations.setResearchFilterDimensionLoaded);
export const commitSetResearchFilterDimensionsTransactions = commit(mutations.setResearchFilterDimensionsTransactions);
export const commitSetResearchFilterCategoryDrillPath = commit(mutations.setResearchFilterCategoryDrillPath);
export const commitSetResearchFilterShowCategoryType = commit(mutations.setResearchFilterShowCategoryType);
export const commitSetResearchExtractTypeSwitchInitialized = commit(mutations.setResearchExtractTypeSwitchInitialized);
export const commitSetResearchExtractTypeSwitchEnabled = commit(mutations.setResearchExtractTypeSwitchEnabled);
export const commitSetResearchExtractTypeSwitchValue = commit(mutations.setResearchExtractTypeSwitchValue);
export const commitSetResearchQuantitiesEnabled = commit(mutations.setResearchQuantitiesEnabled);
export const commitSetResearchHarmonized = commit(mutations.setResearchHarmonized);
export const commitSetResearchValueType = commit(mutations.setResearchValueType);
export const commitSetResearchValueTypeSavings = commit(mutations.setResearchValueTypeSavings);
export const commitSetResearchTimeChartAggregation = commit(mutations.setResearchTimeChartAggregation);
export const commitSetResearchTimeChartAggregationSavings = commit(mutations.commitSetResearchTimeChartAggregationSavings);
export const commitToggleFilterReloadTrigger = commit(mutations.toggleFilterReloadTrigger);
export const commitToggleFilterReloadTriggerTime = commit(mutations.toggleFilterReloadTriggerTime);
export const commitSetDrillChangeElement = commit(mutations.setDrillChangeElement);
export const commitSetLastFilterInteractionSavedView = commit(mutations.setLastInteractionSavedView);
export const commitSetSavedViewValue = commit(mutations.setSavedViewValue);
export const commitSetSavingsPeriodAccrual = commit(mutations.setSavingsPeriodAccrual);
export const commitSetSavingsShowAll = commit(mutations.setSavingsPeriodShowAll);
export const commitSetModuleSpecificDimensions = commit(mutations.setModuleSpecificDimensions);
export const commitSetTransactionSelectedHeaders = commit(mutations.setTransactionSelectedHeaders);
