import { IResearchFilterState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    searchResearchFilterMinTime: (state: IResearchFilterState) => state.time.minTime,
    searchResearchFilterMaxTime: (state: IResearchFilterState) => state.time.maxTime,
    searchResearchFilterCurrentTimePeriod: (state: IResearchFilterState) => state.time.currentPeriod,
    searchResearchFilterCurrentTimePeriodValues: (state: IResearchFilterState) => state.time.currentPeriodValues,
    searchResearchFilterComparePeriod: (state: IResearchFilterState) => state.time.comparePeriod,
    searchResearchFilterComparePeriodValues: (state: IResearchFilterState) => state.time.comparePeriodValues,
    searchResearchFilterDimensions: (state: IResearchFilterState) => state.dimensions,
    searchResearchCurrentlyFilteredCategories: (state: IResearchFilterState) => state.dimensions.find(e => e.dimensionInternalName == 'category_id')?.filteredValues,
    searchResearchFilterDimensionsTransactions: (state: IResearchFilterState) => state.dimensionsTransactions,
    searchResearchFilterDimensionsLoaded: (state: IResearchFilterState) => state.dimensionsLoaded,
    searchResearchCategoryDrillPath: (state: IResearchFilterState) => state.categoryDrillPath,
    searchResearchShowCategoryType: (state: IResearchFilterState) => state.showCategoryTypes,
    searchResearchExtractTypeSwitchInitialized: (state: IResearchFilterState) => state.extractTypeSwitchInitialized,
    searchResearchExtractTypeSwitchEnabled: (state: IResearchFilterState) => state.extractTypeSwitchEnabled,
    searchResearchExtractTypeSwitchValue: (state: IResearchFilterState) => state.extractTypeSwitchValue,
    searchResearchQuantitiesEnabled: (state: IResearchFilterState) => state.quantitiesEnabled,
    searchResearchHarmonized: (state: IResearchFilterState) => state.harmonized,
    searchResearchValueType: (state: IResearchFilterState) => state.valueType,
    searchResearchValueTypeSavings: (state: IResearchFilterState) => state.valueTypeSavings,
    searchResearchTimeChartAggregation: (state: IResearchFilterState) => state.timeChartAggregation,
    searchResearchTimeChartAggregationSavings: (state: IResearchFilterState) => state.timeChartAggregationSavings,
    searchResearchFilterReloadTrigger: (state: IResearchFilterState) => state.reloadTrigger,
    searchResearchFilterReloadTriggerTime: (state: IResearchFilterState) => state.reloadTriggerTime,
    searchResearchDrillChangeElement: (state: IResearchFilterState) => state.categoryDrillChangeElement,
    searchResearchLastInteractionSavedView: (state: IResearchFilterState) => state.lastFilterInteractionSavedView,
    searchResearchSavedViewValue: (state: IResearchFilterState) => state.savedViewValue,
    searchResearchSavingsAccrual: (state: IResearchFilterState) => state.savingsPeriodAccrual,
    searchResearchSavingsShowAll: (state: IResearchFilterState) => state.savingsShowAll,
    searchResearchModuleSpecificDimensions: (state: IResearchFilterState) => state.moduleSpecificDimensions,
    transactionSelectedHeaders: (state: IResearchFilterState) => state.transactionSelectedHeaders,
};

const {read} = getStoreAccessors<IResearchFilterState, State>('');

export const readResearchFilterMinTime = read(getters.searchResearchFilterMinTime);
export const readResearchFilterMaxTime = read(getters.searchResearchFilterMaxTime);
export const readResearchFilterCurrentTimePeriod = read(getters.searchResearchFilterCurrentTimePeriod);
export const readResearchFilterCurrentTimePeriodValues = read(getters.searchResearchFilterCurrentTimePeriodValues);
export const readResearchFilterComparePeriod = read(getters.searchResearchFilterComparePeriod);
export const readResearchFilterComparePeriodValues = read(getters.searchResearchFilterComparePeriodValues);
export const readResearchFilterDimensions = read(getters.searchResearchFilterDimensions);
export const readResearchCurrentlyFilteredCategories = read(getters.searchResearchCurrentlyFilteredCategories);
export const readResearchFilterDimensionsTransactions = read(getters.searchResearchFilterDimensionsTransactions);
export const readResearchFilterDimensionsLoaded = read(getters.searchResearchFilterDimensionsLoaded);
export const readCategoryDrillPath = read(getters.searchResearchCategoryDrillPath);
export const readCategoryShowCategoryType = read(getters.searchResearchShowCategoryType);
export const readExtractTypeSwitchInitialized = read(getters.searchResearchExtractTypeSwitchInitialized);
export const readExtractTypeSwitchEnabled = read(getters.searchResearchExtractTypeSwitchEnabled);
export const readExtractTypeSwitchValue = read(getters.searchResearchExtractTypeSwitchValue);
export const readQuantitiesEnabled = read(getters.searchResearchQuantitiesEnabled);
export const readHarmonized = read(getters.searchResearchHarmonized);
export const readValueType = read(getters.searchResearchValueType);
export const readValueTypeSavings = read(getters.searchResearchValueTypeSavings);
export const readTimeChartAggregation = read(getters.searchResearchTimeChartAggregation);
export const readTimeChartAggregationSavings = read(getters.searchResearchTimeChartAggregationSavings);
export const readFilterReloadTrigger = read(getters.searchResearchFilterReloadTrigger);
export const readFilterReloadTriggerTime = read(getters.searchResearchFilterReloadTriggerTime);
export const readDrillChangeElement = read(getters.searchResearchDrillChangeElement);
export const readLastInteractionSavedView = read(getters.searchResearchLastInteractionSavedView);
export const readSavedViewValue = read(getters.searchResearchSavedViewValue);
export const readSavingsPeriodAccrual = read(getters.searchResearchSavingsAccrual);
export const readSavingsShowAll = read(getters.searchResearchSavingsShowAll);
export const readModuleSpecificDimensions = read(getters.searchResearchModuleSpecificDimensions);
export const readTransactionSelectedHeaders = read(getters.transactionSelectedHeaders);
