import {
  readResearchFilterCurrentTimePeriod,
  readResearchFilterCurrentTimePeriodValues,
  readResearchFilterComparePeriod,
  readResearchFilterComparePeriodValues,
  readResearchFilterDimensions,
  readResearchFilterMinTime,
  readResearchFilterMaxTime,
  readExtractTypeSwitchValue,
  readExtractTypeSwitchEnabled,
} from '@/store/research/getters';

import { IResearchFilterTimeModes } from '@/store/research/state';

import { Store } from 'vuex';
import moment from 'moment';
import { IDataManagementFieldModuleSpecific } from '@/interfaces/dataManagement';
import { IResearchAPIPostPayloadParamsForProjects } from '@/interfaces/research';
import { readActionLogSelectedLifecycles } from '@/store/actionLog/getters';


export function getCurrentFilterPeriods(context: Store<any>, customHandlingIdentifier?: 'NONE' | 'SAVINGS'): string[] {
  const currentPeriod: IResearchFilterTimeModes = readResearchFilterCurrentTimePeriod(context);
  const currentPeriodValues = readResearchFilterCurrentTimePeriodValues(context);
  const maxTime: string = readResearchFilterMaxTime(context);
  let startPeriod!: string;
  let endPeriod!: string;
  if (currentPeriod === 'Gesamt') {
    let start = readResearchFilterMinTime(context);
    let end = readResearchFilterMaxTime(context);
    startPeriod = moment(start).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    endPeriod = moment(end).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    return [startPeriod, endPeriod];
  } else if (currentPeriod === 'YTD') {
    startPeriod = moment().utc(true).startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    endPeriod = moment().utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
  } else if (currentPeriod === 'Jahr') {
    const yearArray: number[] = [];
    currentPeriodValues.forEach(function (year) {
      yearArray.push(parseInt(year));
    });
    yearArray.sort();
    startPeriod = moment().year(yearArray[0]).utc(true).startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    endPeriod = moment().year(yearArray[yearArray.length - 1]).utc(true).endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
  } else if (currentPeriod === 'Quartal') {
    const quarterString: string = currentPeriodValues[0];
    const year: number = parseInt(quarterString.split('-')[0]);
    const quarter: number = parseInt(quarterString.split('-')[1][1]);
    startPeriod = moment().year(year).utc(true).quarter(quarter).startOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
    endPeriod = moment().year(year).utc(true).quarter(quarter).endOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
  } else if (currentPeriod === 'Eigen') {
    currentPeriodValues.sort();
    startPeriod = moment(currentPeriodValues[0]).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    if (currentPeriodValues.length > 1) {
      endPeriod = moment(currentPeriodValues[1]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else {
      endPeriod = moment(currentPeriodValues[0]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
  }

  return [startPeriod, endPeriod];
}

export function compareTimeFilterEnabled(context: Store<any>): boolean {
  const currentPeriod: IResearchFilterTimeModes = readResearchFilterCurrentTimePeriod(context);
  const comparePeriod: string = readResearchFilterComparePeriod(context)[currentPeriod];
  return (comparePeriod === 'Keinen') ? false : true;
}

export function getCompareFilterPeriods(context: Store<any>): string[] {
  const currentPeriod: IResearchFilterTimeModes = readResearchFilterCurrentTimePeriod(context);
  const currentPeriodValues = readResearchFilterCurrentTimePeriodValues(context);
  const comparePeriod: string = readResearchFilterComparePeriod(context)[currentPeriod];
  const comparePeriodValues = readResearchFilterComparePeriodValues(context);
  const maxTime: string = readResearchFilterMaxTime(context);
  let startPeriod!: string;
  let endPeriod!: string;

  if (currentPeriod === 'YTD') {
    if (comparePeriod === 'YTD vor einem Jahr') {
      startPeriod = moment().utc(true).startOf('year').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment(maxTime).utc(true).endOf('day').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (comparePeriod === 'YTD im Jahr') {
      startPeriod = moment().year(parseInt(comparePeriodValues[0])).utc(true).startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      const compareYear: number = moment(comparePeriodValues[0]).year();
      const currentYear: number = moment().year();
      const yearDiff: number = currentYear - compareYear;
      if (yearDiff > 0) {
        endPeriod = moment(maxTime).utc(true).endOf('day').subtract(yearDiff, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        endPeriod = moment(maxTime).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      }
    } else if (comparePeriod === 'Eigen') {
      comparePeriodValues.sort();
      startPeriod = moment(comparePeriodValues[0]).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      if (comparePeriodValues.length > 1) {
        endPeriod = moment(comparePeriodValues[1]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        endPeriod = moment(comparePeriodValues[0]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      }
    }
  } else if (currentPeriod === 'Jahr') {
    if (comparePeriod === 'Jahr davor') {
      startPeriod = moment().year(parseInt(currentPeriodValues[0])).utc(true).subtract(1, 'year').startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment().year(parseInt(currentPeriodValues[0])).utc(true).subtract(1, 'year').endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (comparePeriod === 'Jahr') {
      startPeriod = moment().year(parseInt(comparePeriodValues[0])).utc(true).startOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment().year(parseInt(comparePeriodValues[0])).utc(true).endOf('year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (comparePeriod === 'Eigen') {
      comparePeriodValues.sort();
      startPeriod = moment(comparePeriodValues[0]).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      if (comparePeriodValues.length > 1) {
        endPeriod = moment(comparePeriodValues[1]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        endPeriod = moment(comparePeriodValues[0]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      }
    }
  } else if (currentPeriod === 'Quartal') {
    if (comparePeriod === 'Selbes im Vorjahr') {
      const quarterString: string = currentPeriodValues[0];
      const year: number = parseInt(quarterString.split('-')[0]);
      const quarter: number = parseInt(quarterString.split('-')[1][1]);
      startPeriod = moment().year(year).utc(true).quarter(quarter).subtract(1, 'year').startOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment().year(year).utc(true).quarter(quarter).subtract(1, 'year').endOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (comparePeriod === 'Quartal') {
      const quarterString: string = comparePeriodValues[0];
      const year: number = parseInt(quarterString.split('-')[0]);
      const quarter: number = parseInt(quarterString.split('-')[1][1]);
      startPeriod = moment().year(year).utc(true).quarter(quarter).startOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment().year(year).utc(true).quarter(quarter).endOf('quarter').format('YYYY-MM-DDTHH:mm:ss.SSS');
    }
  } else if (currentPeriod === 'Eigen') {
    if (comparePeriod === 'Selber letztes Jahr') {
      currentPeriodValues.sort();
      startPeriod = moment(currentPeriodValues[0]).utc(true).startOf('day').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      endPeriod = moment(currentPeriodValues[1]).utc(true).endOf('day').subtract(1, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
    } else if (comparePeriod === 'Selber im Jahr') {
      currentPeriodValues.sort();
      const compareYear: number = moment(comparePeriodValues[0]).year();
      const currentYear: number = moment(currentPeriodValues[0]).utc(true).year();
      const yearDiff: number = currentYear - compareYear;
      if (yearDiff > 0) {
        startPeriod = moment(currentPeriodValues[0]).utc(true).startOf('day').subtract(yearDiff, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
        endPeriod = moment(currentPeriodValues[1]).utc(true).endOf('day').subtract(yearDiff, 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else if (yearDiff < 0) {
        startPeriod = moment(currentPeriodValues[0]).utc(true).startOf('day').add(yearDiff * (-1), 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
        endPeriod = moment(currentPeriodValues[1]).utc(true).endOf('day').add(yearDiff * (-1), 'year').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        startPeriod = moment(currentPeriodValues[0]).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
        endPeriod = moment(currentPeriodValues[1]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      }
    } else if (comparePeriod === 'Eigen') {
      comparePeriodValues.sort();
      startPeriod = moment(comparePeriodValues[0]).utc(true).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      if (comparePeriodValues.length > 1) {
        endPeriod = moment(comparePeriodValues[1]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      } else {
        endPeriod = moment(comparePeriodValues[0]).utc(true).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS');
      }
    }
  }
  return [startPeriod, endPeriod];
}

export function getCompareDateDiff(startPeriod: string, endPeriod: string, unit = 'years'): number {
  let diff = NaN;
  if (unit == 'years') {
    const startDate = moment(startPeriod).utc(true);
    const endDate = moment(endPeriod).utc(true);
    diff = startDate.diff(endDate, 'years');
  } else if (unit == 'quarters') {
    const startDate = moment(startPeriod);
    const endDate = moment(endPeriod);
    diff = startDate.diff(endDate, 'quarters');
  }
  return diff;
}

export function getDimensionFilters(context: Store<any>, extractTypeEnabled: boolean = true, filterOutModelSpecifics: boolean = true): [string[], string[][], string[], string[][], boolean] {
  const dimensionsRegular: string[] = [];
  const valuesRegular: string[][] = [];
  const dimensionsNE: string[] = [];
  const valuesNE: string[][] = [];
  let includeChildrens = true;
  readResearchFilterDimensions(context).forEach(function (filterDimension) {
    if (!filterDimension.moduleSpecific || !filterOutModelSpecifics) {
      if (filterDimension.filteredValues.length > 0) {
        const dimensionValues: string[] = [];
        if (filterDimension.customHandling) {
          if (filterDimension.customHandlingIdentifier === 'CATEGORY') {
            dimensionsRegular.push('category_id');
            filterDimension.filteredValues.forEach(function (value) {
              if (value['_id'] == '--') {
                dimensionValues.push(value['parents_ids'][value['parents_ids'].length - 1]);
                includeChildrens = false;
              } else {
                dimensionValues.push(value['_id']);
              }
            });
          } else if (filterDimension.customHandlingIdentifier === 'VENDOR_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('vendor_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('vendor_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'EXTRACT') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('file_ids');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('file_ids');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'PROFITCENTER_ID') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('profit_center_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('profit_center_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'PROFITCENTER_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('profit_center_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('profit_center_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'GL_ID') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('general_ledger_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('general_ledger_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'GL_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('general_ledger_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('general_ledger_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'MATERIAL_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('material_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('material_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'COSTCENTER_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('cost_center_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('cost_center_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'COSTUNIT_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('cost_unit_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('cost_unit_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          } else if (filterDimension.customHandlingIdentifier === 'COMPANYCODE_NAME') {
            if (!filterDimension.filterSelectAll) {
              dimensionsRegular.push('company_code_id');
              filterDimension.filteredValues.forEach(function (value) {
                dimensionValues.push(value['_id']);
              });
            } else {
              // return NE-Filters by identifing missing ones of filteredValues in availableOptions
              dimensionsNE.push('company_code_id');
              filterDimension.availableOptions.forEach(function (value) {
                if (!(filterDimension.filteredValues.filter(e => e['_id'] == value['_id']).length > 0)) dimensionValues.push(value['_id']);
              });
            }
          }
        } else {
          if (!filterDimension.filterSelectAll) {
            dimensionsRegular.push(filterDimension.dimensionInternalName);
            filterDimension.filteredValues.forEach(function (value) {
              dimensionValues.push(('_id' in value) ? value['_id'] : value['name']);
            });
          } else {
            // return NE-Filters by identifing missing ones of filteredValues in availableOptions
            dimensionsNE.push(filterDimension.dimensionInternalName);
            filterDimension.availableOptions.forEach(function (value) {
              if (!(filterDimension.filteredValues.filter(e => e['name'] == value['name']).length > 0)) dimensionValues.push(('_id' in value) ? value['_id'] : value['name']);
            });
          }
        }
        if (!filterDimension.filterSelectAll || (filterDimension.customHandling && filterDimension.customHandlingIdentifier === 'CATEGORY')) {
          valuesRegular.push(dimensionValues);
        } else {
          valuesNE.push(dimensionValues);
        }
      }
    }
  });
  // check for extract type
  if (readExtractTypeSwitchEnabled(context) && extractTypeEnabled) {
    const switchValue = readExtractTypeSwitchValue(context);
    if (switchValue && switchValue !== 'ALL') {
      dimensionsRegular.push('type');
      valuesRegular.push([switchValue]);
    }
  }
  return [dimensionsRegular, valuesRegular, dimensionsNE, valuesNE, includeChildrens];
}

export function getDimensionFiltersModuleSpecific(context: Store<any>, module: IDataManagementFieldModuleSpecific): [string[], string[][], string[], string[][]] {
  const dimensionsRegular: string[] = [];
  const valuesRegular: string[][] = [];
  const dimensionsNE: string[] = [];
  const valuesNE: string[][] = [];
  readResearchFilterDimensions(context).forEach(function (filterDimension) {
    if (filterDimension.moduleSpecific && filterDimension.moduleSpecific == module) {
      if (filterDimension.filteredValues.length > 0) {
        const dimensionValues: string[] = [];

        if (!filterDimension.filterSelectAll) {
          dimensionsRegular.push(filterDimension.dimensionInternalName);
          filterDimension.filteredValues.forEach(function (value) {
            let pushFilterValue: string = ('_id' in value) ? value['_id'] : value['name'];
            dimensionValues.push(pushFilterValue);
          });
        } else {
          // return NE-Filters by identifing missing ones of filteredValues in availableOptions
          dimensionsNE.push(filterDimension.dimensionInternalName);
          filterDimension.availableOptions.forEach(function (value) {
            let pushFilterValue: string = ('_id' in value) ? value['_id'] : value['name'];
            if (!(filterDimension.filteredValues.filter(e => e['name'] == value['name']).length > 0)) dimensionValues.push(pushFilterValue);
          });
        }

        if (!filterDimension.filterSelectAll || (filterDimension.customHandling && filterDimension.customHandlingIdentifier === 'CATEGORY')) {
          valuesRegular.push(dimensionValues);
        } else {
          valuesNE.push(dimensionValues);
        }
      }
    }
  });

  return [dimensionsRegular, valuesRegular, dimensionsNE, valuesNE];
}

export function lifecyclesGetParamsForProjects(context: Store<any>): IResearchAPIPostPayloadParamsForProjects {
  // start with lifecycles
  let actionsSelectedLifecyles = readActionLogSelectedLifecycles(context);
  let paramsForProjects: IResearchAPIPostPayloadParamsForProjects = (actionsSelectedLifecyles.length == 1 && actionsSelectedLifecyles[0] === 'ALL') ? {
    filter_fields: [],
    filter_values: [],
    ne_filter_fields: [],
    ne_filter_values: [],
  } : {
    filter_fields: ["status"],
    filter_values: [actionsSelectedLifecyles],
    ne_filter_fields: [],
    ne_filter_values: [],
  };

  let filtersForProjects = getDimensionFiltersModuleSpecific(context, 'PROJECT');
  if (filtersForProjects[0].length > 0 && filtersForProjects[1].length > 0) {
    for (let filter of filtersForProjects[0]) {
      paramsForProjects.filter_fields.push(filter);
    }
    for (let filterValues of filtersForProjects[1]) {
      paramsForProjects.filter_values.push(filterValues);
    }
  }
  if (filtersForProjects[2].length > 0 && filtersForProjects[3].length > 0) {
    for (let filterNE of filtersForProjects[2]) {
      paramsForProjects.ne_filter_fields.push(filterNE);
    }
    for (let filterNEValues of filtersForProjects[3]) {
      paramsForProjects.ne_filter_values.push(filterNEValues);
    }
  }
  return paramsForProjects;
}

export function lifecyclesGetLifecycleFilter(context: Store<any>): IResearchAPIPostPayloadParamsForProjects {
  // start with lifecycles
  let actionsSelectedLifecyles = readActionLogSelectedLifecycles(context);
  let paramsForProjects: IResearchAPIPostPayloadParamsForProjects = (actionsSelectedLifecyles.length == 1 && actionsSelectedLifecyles[0] === 'ALL') ? {
    filter_fields: [],
    filter_values: [],
    ne_filter_fields: [],
    ne_filter_values: [],
  } : {
    filter_fields: ["status"],
    filter_values: [actionsSelectedLifecyles],
    ne_filter_fields: [],
    ne_filter_values: [],
  };
  return paramsForProjects;
}

export function getSavingsDashboardFilters(context: Store<any>): [string[], string[][], string[], string[][]] {
  // normal filters
  let filterFields = getDimensionFilters(context, false)[0];
  let filterValues = getDimensionFilters(context, false)[1];
  let filterFieldsNE = getDimensionFilters(context, false)[2];
  let filterValuesNE = getDimensionFilters(context, false)[3];

  // add savings position specific filters
  let positionSpecifics = getDimensionFiltersModuleSpecific(context, 'SAVINGS_POSITION');
  filterFields = filterFields.concat(positionSpecifics[0]);
  filterValues = filterValues.concat(positionSpecifics[1]);
  filterFieldsNE = filterFieldsNE.concat(positionSpecifics[2]);
  filterValuesNE = filterValuesNE.concat(positionSpecifics[3]);

  return [filterFields, filterValues, filterFieldsNE, filterValuesNE];
}
